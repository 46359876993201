import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import OutputBlock from "./components/OutputBlock";
import InputsSection from "./components/InputSection";

const RouteGenerator = () => {
    const [resourceName, setResourceName] = useState("");
    const [copied, setCopied] = useState(false);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const basePath = `/api/v1/${resourceName}`;
    const routes = [
        `router.get('${basePath}', ${resourceName}Controller.get${capitalize(resourceName)});`,
        `router.get('${basePath}/:id', ${resourceName}Controller.get${capitalize(resourceName)}ById);`,
        `router.post('${basePath}', verifyToken, ${resourceName}Controller.add${capitalize(resourceName)});`,  // Added verifyToken
        `router.post('${basePath}/bulk', verifyToken, ${resourceName}Controller.bulkAdd${capitalize(resourceName)});`,  // Added verifyToken
        `router.put('${basePath}/:id', ${resourceName}Controller.edit${capitalize(resourceName)});`,
        `router.patch('${basePath}/:id', ${resourceName}Controller.update${capitalize(resourceName)});`,
        `router.put('${basePath}-ops/hidden/:id', ${resourceName}Controller.toggleHidden${capitalize(resourceName)});`,
        `router.post('${basePath}-ops/order', ${resourceName}Controller.set${capitalize(resourceName)}Order);`,
        `router.delete('${basePath}/:id', ${resourceName}Controller.delete${capitalize(resourceName)});`,
    ];

    const allRoutes = `
const router = require('express').Router();
const ${resourceName}Controller = require('../Controller/${resourceName}Controller');
const { verifyToken } = require('../Service/AuthService');

// Routes for ${resourceName}
${routes.join("\n")}

module.exports = router;
  `.trim();

    const controllerTemplate = `
const { sendResponse } = require('../Middleware/sendResponse');
const ${capitalize(resourceName)}Model = require('../Models/${capitalize(resourceName)}Model');
const { createPipeline } = require('../Service/AggregatorService');
const JoiSchemaService = require('../Service/JoiSchemaService');
const MongooseService = require('../Service/MongooseService');

const ${resourceName}Service = new MongooseService(${capitalize(resourceName)}Model);

const SearchMap = {
    ${resourceName}: ['name', 'description']
};

// Get all ${resourceName}
exports.get${capitalize(resourceName)} = async (req, res) => {
    try {
        const { search = '', page = 1, limit = 10, lookupField } = req.query;
        const searchFields = SearchMap.${resourceName}.map(field => ({
            [field]: { $regex: search, $options: 'i' }
        }));
        const searchQuery = search ? { $or: searchFields } : {};
        const skip = (page - 1) * limit;
        const filters = {
            filters: searchQuery,
            pagination: { skip, limit: parseInt(limit) },
            sort: { createdAt: -1 },
            lookupField
        };

        const pipeline = createPipeline(filters);
        const totalCount = await ${resourceName}Service.countDocuments(searchQuery);
        const data = await ${resourceName}Service.aggregate(pipeline);
        const pagination = {
            totalRecords: totalCount,
            totalPages: Math.ceil(totalCount / limit),
            currentPage: parseInt(page),
            limit: parseInt(limit),
        };

        return sendResponse(res, 200, { list: data, pagination });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to fetch ${resourceName}' });
    }
};

// Get ${resourceName} by ID
exports.get${capitalize(resourceName)}ById = async (req, res) => {
    try {
        const { id } = req.params;
        if (!id.match(/^[0-9a-fA-F]{24}$/)) {
            return sendResponse(res, 400, { info: 'Invalid ID format' });
        }
        const defaultFilters = req.query || {};
        const filters = { id, ...defaultFilters };
        const pipeline = createPipeline({
            filters,
            sort: { createdAt: -1 },
        });

        const [data] = await ${resourceName}Service.aggregate(pipeline);
        if (!data) {
            return sendResponse(res, 404, { info: '${capitalize(resourceName)} not found' });
        }

        return sendResponse(res, 200, { resource: data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to fetch ${resourceName}' });
    }
};

// Add new ${resourceName}
exports.add${capitalize(resourceName)} = async (req, res) => {
    try {
        const user = req.user;
        if (!user || !user.uid) {
            return sendResponse(res, 400, { info: 'Invalid user' });
        }

        const { isValid, errors } = JoiSchemaService.validate(req.body, JoiSchemaService.${resourceName}Schema);
        if (!isValid) return sendResponse(res, 400, { info: errors[0], details: errors });

        const newData = {
            ...req.body,
            createdBy: user.uid,
            createdAt: Date.now(),
            updatedAt: Date.now(),
        };
        const data = await ${resourceName}Service.create(newData);
        return sendResponse(res, 201, { info: '${capitalize(resourceName)} added successfully', data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to add ${resourceName}' });
    }
};

// Bulk Add ${resourceName}
exports.bulkAdd${capitalize(resourceName)} = async (req, res) => {
    try {
        const user = req.user;
        if (!user || !user.uid) {
            return sendResponse(res, 400, { info: 'Invalid user' });
        }

        const { list } = req.body; // Expecting [{ field1, field2, ... }]
        if (!Array.isArray(list) || list.length === 0) {
            return sendResponse(res, 400, { info: 'Invalid input. Expected an array of resources.' });
        }

        const validationResults = list.map((resource) => {
            return JoiSchemaService.validate(resource, JoiSchemaService.${resourceName}Schema);
        });

        const invalidResources = validationResults.filter(({ isValid }) => !isValid);
        if (invalidResources.length > 0) {
            return sendResponse(res, 400, {
                info: 'Validation errors in bulk add',
                details: invalidResources.map(({ errors }) => errors),
            });
        }

        const preparedData = list.map((resource) => ({
            ...resource,
            createdBy: user.uid,
            createdAt: Date.now(),
            updatedAt: Date.now(),
        }));

        const data = await ${resourceName}Service.insertMany(preparedData);
        return sendResponse(res, 201, {
            info: '${capitalize(resourceName)} bulk added successfully',
            data,
        });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to perform bulk add operation' });
    }
};

// Edit ${resourceName}
exports.edit${capitalize(resourceName)} = async (req, res) => {
    try {
        const { id } = req.params;
        const { isValid, errors } = JoiSchemaService.validate(req.body, JoiSchemaService.${resourceName}Schema);
        if (!isValid) return sendResponse(res, 400, { info: errors[0], details: errors });

        const updatedData = { ...req.body, updatedAt: Date.now() };
        const data = await ${resourceName}Service.updateById(id, updatedData);
        if (!data) return sendResponse(res, 404, { info: '${capitalize(resourceName)} not found' });
        return sendResponse(res, 200, { info: '${capitalize(resourceName)} updated successfully', data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to update ${resourceName}' });
    }
};

// Update partial ${resourceName}
exports.update${capitalize(resourceName)} = async (req, res) => {
    try {
        const { id } = req.params;
        if (!req.body || Object.keys(req.body).length === 0) {
            return sendResponse(res, 400, { info: 'No data provided for update' });
        }

        const updatedData = { ...req.body, updatedAt: Date.now() };
        const data = await ${resourceName}Service.updateById(id, updatedData, { new: true });
        if (!data) return sendResponse(res, 404, { info: '${capitalize(resourceName)} not found' });

        return sendResponse(res, 200, { info: '${capitalize(resourceName)} updated partially', data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to update ${resourceName}' });
    }
};

// Toggle hidden field for a ${resourceName}
exports.toggleHidden${capitalize(resourceName)} = async (req, res) => {
    try {
        const { id } = req.params;
        const { hidden } = req.body; // Expecting { hidden: true/false }
        const data = await ${resourceName}Service.updateById(id, { hidden }, { new: true });
        if (!data) return sendResponse(res, 404, { info: '${capitalize(resourceName)} not found' });
        return sendResponse(res, 200, { info: 'Hidden status updated successfully', data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to update hidden status' });
    }
};

// Update order for a list of ${resourceName}
exports.set${capitalize(resourceName)}Order = async (req, res) => {
    try {
        const { list } = req.body; // Expecting [{ _id, order }]
        if (!Array.isArray(list) || list.length === 0) {
            return sendResponse(res, 400, { info: 'Invalid input. Expected an array of {_id, order}.' });
        }

        const bulkOperations = list.map(({ _id, order }) => ({
            updateOne: {
                filter: { _id },
                update: { order },
            },
        }));

        const result = await ${capitalize(resourceName)}Model.bulkWrite(bulkOperations);
        return sendResponse(res, 200, {
            info: 'Order updated successfully',
            data: { matchedCount: result.matchedCount, modifiedCount: result.modifiedCount },
        });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to update order' });
    }
};

// Delete ${resourceName}
exports.delete${capitalize(resourceName)} = async (req, res) => {
    try {
        const { id } = req.params;
        const data = await ${resourceName}Service.deleteById(id);
        if (!data) return sendResponse(res, 404, { info: '${capitalize(resourceName)} not found' });
        return sendResponse(res, 200, { info: '${capitalize(resourceName)} deleted successfully', data });
    } catch (error) {
        return sendResponse(res, 500, { info: 'Failed to delete ${resourceName}' });
    }
};
  `.trim();

    const handleCopy = (text) => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <Box sx={{ p: 2, maxWidth: "100%", margin: "auto", textAlign: "center" }}>
            <Typography variant="h4" mb={2}>
                Express Route & Controller Generator
            </Typography>
            <InputsSection
                resourceName={resourceName}
                setResourceName={setResourceName}
            />
            <Box sx={{}}>
                <OutputBlock
                    title="Generated Routes"
                    code={allRoutes}
                    copied={copied}
                    handleCopy={handleCopy}
                />
                <OutputBlock
                    title="Generated Controller"
                    code={controllerTemplate}
                    copied={copied}
                    handleCopy={handleCopy}
                />
            </Box>

            {/* Copied Feedback */}
            {copied && (
                <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                    Copied to clipboard!
                </Typography>
            )}
        </Box>
    );
};

export default RouteGenerator;
