import React from "react";
import { TextField, Box } from "@mui/material";

const InputsSection = ({ resourceName, setResourceName }) => {
    return (
        <Box sx={{ mb: 1, display: 'flex', gap: '5px' }}>
            <TextField
                label="Resource Name"
                variant="outlined"
                fullWidth
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value)}
                placeholder="e.g., customers"
            />
        </Box>
    );
};

export default InputsSection;
