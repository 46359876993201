import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const OutputBlock = ({ title, code, copied, handleCopy }) => {
    return (
        <Box sx={{ mt: 3, position: "relative" }}>
            <Typography variant="h6" sx={{ textAlign: "left" }}>
                {title}
            </Typography>
            <SyntaxHighlighter
                language="javascript"
                style={materialDark}
                customStyle={{ fontSize: '12px', padding: "1em", borderRadius: "8px", maxHeight: "100%" }}
            >
                {code}
            </SyntaxHighlighter>
            <CopyToClipboard text={code} onCopy={() => handleCopy(code)}>
                <IconButton sx={{ position: "absolute", top: 40, right: 10 }}>
                    <ContentCopyIcon color={copied ? "success" : "primary"} />
                </IconButton>
            </CopyToClipboard>
        </Box>
    );
};

export default OutputBlock;
