import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CodeOutput = ({ title, code, copied, handleCopy }) => {
    return (
        <Box sx={{ mb: 3, position: "relative" }}>
            <Typography variant="h6" sx={{ textAlign: "left" }}>
                {title}
            </Typography>
            <SyntaxHighlighter language="javascript" style={materialDark}>
                {code}
            </SyntaxHighlighter>
            <CopyToClipboard text={code} onCopy={handleCopy}>
                <IconButton sx={{ position: "absolute", top: 40, right: 10 }}>
                    <ContentCopyIcon color={copied ? "success" : "primary"} />
                </IconButton>
            </CopyToClipboard>
        </Box>
    );
};

export default CodeOutput;
