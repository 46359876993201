import React, { useState } from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import OutputBlock from "../RouteGenerator/components/OutputBlock";

const AggregationGenerator = () => {
    const [fieldName, setFieldName] = useState("quizId");
    const [collectionName, setCollectionName] = useState("Quiz");

    const aggregations = [
        {
            title: `Aggregation 1`,
            code: `{
    $lookup: {
        from: '${collectionName}',
        let: { ${fieldName}: '$_id' },
        pipeline: [
            {
                $match: {
                    $expr: {
                        $eq: ['$${fieldName}', { $toString: '$$${fieldName}' }]
                    }
                }
            },
        ],
        as: '${collectionName.toLowerCase()}'
    }
}`
        },
        {
            title: `Aggregation 2`,
            code:
                `{
    $lookup: {
        from: '${collectionName}',
        let: { ${fieldName}: { $toObjectId: '$${fieldName}' } },
        pipeline: [
            {
                $match: {
                    $expr: { $eq: ['$_id', '$$${fieldName}'] }
                }
            }
        ],
        as: '${collectionName.toLowerCase()}'
    }
}`
        }
    ]

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
                Dynamic Aggregation Generator
            </Typography>
            {/* Input Form */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Collection Name"
                        fullWidth
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                        placeholder="e.g., Questions"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Field Name"
                        fullWidth
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)}
                        placeholder="e.g., quizId"
                    />
                </Grid>
            </Grid>

            {/* Generated Aggregations */}
            <Grid container spacing={2}>
                {aggregations.map((aggregation, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <OutputBlock
                            title={aggregation.title}
                            code={aggregation.code}
                            copied={false} // Reset copied state for each block
                            handleCopy={() => navigator.clipboard.writeText(aggregation.code)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AggregationGenerator;
