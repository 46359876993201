import React from "react";
import { Box, TextField, Button, Checkbox, FormControlLabel } from "@mui/material";

const FieldInput = ({ field, index, dataTypes, updateField, removeField }) => {
    return (
        <Box
            key={index}
            sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                gap: "10px",
            }}
        >
            <TextField
                label="Key Name"
                variant="outlined"
                value={field.key}
                onChange={(e) => updateField(index, "key", e.target.value)}
            />
            <TextField
                label="Type"
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={field.type}
                onChange={(e) => updateField(index, "type", e.target.value)}
            >
                {dataTypes.map((type) => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </TextField>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={field.required}
                        onChange={(e) => updateField(index, "required", e.target.checked)}
                    />
                }
                label="Required"
            />
            <TextField
                label="Default Value"
                variant="outlined"
                value={field.default}
                onChange={(e) => updateField(index, "default", e.target.value)}
            />
            <Button
                variant="contained"
                color="error"
                onClick={() => removeField(index)}
            >
                Remove
            </Button>
        </Box>
    );
};

export default FieldInput;
