import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RouteGenerator from "../views/features/RouteGenerator/RouteGenerator.js";
import ModelAndSchemaGenerator from "../views/features/ModelSchemaGenerator/ModelSchemaGenerator.js";
import AggregationGenerator from "../views/features/AggregationGenerator/AggregationGenerator.jsx";


/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));

const MongoUpdater = lazy(() => import("../views/projects/mongo-updates/MongoUpdater.js"));

const ProjectsList = lazy(() => import("../views/projects/list/ProjectsList.js"))
const BashGenerator = lazy(() => import("../views/features/BashGenerator.js"))
const StarRating = lazy(() => import("../views/features/StarRating.js"))
const APIClient = lazy(() => import("../views/features/APIClient.js"))
const PracticeQuestions = lazy(() => import("../views/features/PracticeQuestions.js"))
const GigTracker = lazy(() => import("../views/features/GigTracker.js"))
const SecretsManager = lazy(() => import("../views/features/SecretsManager.js"))
const RequestLogs = lazy(() => import("../views/features/RequestLogs.js"))
const AddProject = lazy(() => import("../views/features/AddProject.js"))

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="dashboards/dashboard1" /> },
      { path: "dashboards/dashboard1", exact: true, element: <Dashboard1 /> },
      { path: "projects/mongo-updates", exact: true, element: <MongoUpdater /> },
      { path: "features/bash-generator", exact: true, element: <BashGenerator /> },
      { path: "features/route-generator", exact: true, element: <RouteGenerator /> },
      { path: "features/model-schema-generator", exact: true, element: <ModelAndSchemaGenerator /> },
      { path: "features/aggregation-generator", exact: true, element: <AggregationGenerator /> },
      { path: "features/star-rating", exact: true, element: <StarRating /> },
      { path: "projects/list", exact: true, element: <ProjectsList /> },
      { path: "features/api-client", exact: true, element: <APIClient /> },
      { path: "features/practice-questions", exact: true, element: <PracticeQuestions /> },
      { path: "features/gig-tracker", exact: true, element: <GigTracker /> },
      { path: "features/secrets-manager", exact: true, element: <SecretsManager /> },
      { path: "features/request-logs", exact: true, element: <RequestLogs /> },
      { path: "projects/new", exact: true, element: <AddProject /> },

      // Others
      { path: "tables/basic-table", element: <BasicTable /> },
      { path: "/form-layouts/form-layouts", element: <FormLayouts /> },
      { path: "/form-elements/autocomplete", element: <ExAutoComplete /> },
      { path: "/form-elements/button", element: <ExButton /> },
      { path: "/form-elements/checkbox", element: <ExCheckbox /> },
      { path: "/form-elements/radio", element: <ExRadio /> },
      { path: "/form-elements/slider", element: <ExSlider /> },
      { path: "/form-elements/switch", element: <ExSwitch /> },
    ],
  },
];

export default ThemeRoutes;
