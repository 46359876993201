/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import FieldInput from "./components/FieldInput";
import CodeOutput from "./components/CodeOutput";

function lowercaseFirstChar(str) {
    if (str.length === 0) return str;  // Return empty string if input is empty
    return str.charAt(0).toLowerCase() + str.slice(1);
}

const ModelAndSchemaGenerator = () => {
    const [fields, setFields] = useState([
        { key: "name", type: "String", required: true, default: "" },
        { key: "description", type: "String", required: false, default: "" },
        { key: "imageURL", type: "String", required: false, default: "https://www.thewall360.com/uploadImages/ExtImages/images1/def-638240706028967470.jpg" },
    ]);
    const [copied, setCopied] = useState(false);
    const [collectionName, setCollectionName] = useState("ModelName");

    const dataTypes = ["String", "Number", "Boolean", "Date", "Array", "ObjectId"];

    const addField = () => {
        setFields([...fields, { key: "", type: "String", required: false, default: "" }]);
    };

    const updateField = (index, property, value) => {
        const updatedFields = fields.map((field, idx) =>
            idx === index ? { ...field, [property]: value } : field
        );
        setFields(updatedFields);
    };

    const removeField = (index) => {
        setFields(fields.filter((_, idx) => idx !== index));
    };

    const generateMongooseModel = () => {
        const schemaFields = fields
            .map((field) => {
                let fieldDefinition = `${field.key}: {\n        type: ${field.type}`;
                if (field.required) fieldDefinition += `,\n        required: true`;
                if (field.default !== "") fieldDefinition += `,\n        default: ${JSON.stringify(field.default)}`;
                fieldDefinition += `\n    }`;
                return fieldDefinition;
            })
            .join(",\n    ");

        return `
const mongoose = require('mongoose');

const schema = mongoose.Schema({
    ${schemaFields},
    createdBy: {
        type: String
    },
    createdAt: {
        type: Number,
        default: () => Date.now(),
    },
    updatedAt: {
        type: Number,
        default: () => Date.now(),
    }
});

module.exports = mongoose.model('${collectionName}', schema, '${collectionName}');
        `.trim();
    };

    const generateJoiSchema = () => {
        const schemaFields = fields
            .filter((field) => field.key !== "createdAt" && field.key !== "updatedAt")
            .map((field) => {
                let joiField = `Joi.${field.type.toLowerCase()}()`;
                if (field.required) {
                    joiField += `.required().messages({ 'any.required': '${field.key} is required' })`;
                } else {
                    joiField += `.optional()`;
                }
                if (field.type === "String" && !field.required) {
                    joiField += `.allow('').messages({ 'string.empty': '${field.key} cannot be empty' })`;
                }
                return `${field.key}: ${joiField}`;
            })
            .join(",\n    ");

        return `
static ${lowercaseFirstChar(collectionName)}Schema = Joi.object({
    ${schemaFields}
});
        `.trim();
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <Box sx={{ p: 2, maxWidth: "100%", margin: "auto", textAlign: "center" }}>
            <Typography variant="h4" mb={2}>
                Mongoose Model & Joi Schema Generator
            </Typography>

            <TextField
                label="Collection Name"
                variant="outlined"
                fullWidth
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                sx={{ mb: 3 }}
            />

            {fields.map((field, index) => (
                <FieldInput
                    key={index}
                    field={field}
                    index={index}
                    dataTypes={dataTypes}
                    updateField={updateField}
                    removeField={removeField}
                />
            ))}

            <Button variant="contained" onClick={addField} sx={{ mb: 3 }}>
                Add Field
            </Button>

            {/* Two Code Outputs in one row */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <CodeOutput
                        title="Mongoose Model"
                        code={generateMongooseModel()}
                        copied={copied}
                        handleCopy={handleCopy}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <CodeOutput
                        title="Joi Schema"
                        code={generateJoiSchema()}
                        copied={copied}
                        handleCopy={handleCopy}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ModelAndSchemaGenerator;
